import { render, staticRenderFns } from "./voteBtn.vue?vue&type=template&id=9c8a365c&scoped=true"
import script from "./voteBtn.vue?vue&type=script&lang=js"
export * from "./voteBtn.vue?vue&type=script&lang=js"
import style0 from "./voteBtn.vue?vue&type=style&index=0&id=9c8a365c&prod&lang=less&scoped=true"
import style1 from "./voteBtn.vue?vue&type=style&index=1&id=9c8a365c&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c8a365c",
  null
  
)

export default component.exports