import { render, staticRenderFns } from "./dialog_company.vue?vue&type=template&id=8e7cb0c6&scoped=true"
import script from "./dialog_company.vue?vue&type=script&lang=js"
export * from "./dialog_company.vue?vue&type=script&lang=js"
import style0 from "./dialog_company.vue?vue&type=style&index=0&id=8e7cb0c6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e7cb0c6",
  null
  
)

export default component.exports